const root = document.querySelector('div.wrapper')

// Section Actions
const sections = document.querySelectorAll('section')
const sectionObserver = new IntersectionObserver(
    entries => {
        entries.forEach(entry => {
            entry.target.classList.toggle('is-visible', entry.isIntersecting)
            if (entry.isIntersecting) sectionObserver.unobserve(entry.target)
        })
    },
    {
        root,
        rootMargin: '0px 0px 20px 0px',
    }
)
sections.forEach(section => {
    sectionObserver.observe(section)
})

// const breaks = document.querySelectorAll('section.storybook li.story h2')
// breaks.forEach(breakElement => {
//     const text = (breakElement as HTMLElement).innerText
//     const newText = text.replace(/:/g, ':\n')
//     ;(breakElement as HTMLElement).innerHTML = newText.replace(/\n/g, '<br/>')
// })

// // Video Actions
// const heroVideo = document.getElementById('heroVideo') as HTMLVideoElement

// const loadVideo = () => {
//     heroVideo.innerHTML =
//         '<source src="/assets/_video/placeholder.mp4" type="video/mp4">'
//     // if (window.innerWidth < 768) {
//     //     heroVideo.innerHTML =
//     //         '<source src="/assets/_video/nwd-placeholder-video-360.mp4" type="video/mp4">'
//     // } else {
//     //     heroVideo.innerHTML =
//     //         '<source src="/assets/_video/nwd-placeholder-video-720.mp4" type="video/mp4">'
//     // }
//     heroVideo.load()

//     heroVideo.removeEventListener('canplay', loadVideo)
// }
// loadVideo()

// const videoObserver = new IntersectionObserver(
//     entries => {
//         entries.forEach(entry => {
//             if (!entry.isIntersecting) {
//                 heroVideo.pause()
//             } else {
//                 heroVideo.play()
//             }
//         })
//     },
//     {
//         root,
//         threshold: 0.75,
//     }
// )
// videoObserver.observe(heroVideo)

// const muteSound = () => {
//     if (heroVideo.muted) {
//         heroVideo.muted = false
//     } else {
//         heroVideo.muted = true
//     }
// }
// const btnHideVideo = document.getElementById('hideVideo') as HTMLButtonElement

// const shutdownVideo = () => {
//     const div = btnHideVideo.parentElement as HTMLDivElement
//     heroVideo.muted = true
//     div.classList.add('hide')

//     setTimeout(() => {
//         heroVideo.removeEventListener('click', muteSound)
//         heroVideo.removeEventListener('ended', shutdownVideo)
//         btnHideVideo.removeEventListener('click', shutdownVideo)

//         videoObserver.unobserve(heroVideo)

//         div.remove()
//     }, 1000)
// }

// // heroVideo.addEventListener('canplay', loadVideo)
// heroVideo.addEventListener('click', muteSound)
// heroVideo.addEventListener('ended', shutdownVideo)
// btnHideVideo.addEventListener('click', shutdownVideo)
